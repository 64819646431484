<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>组织申报信息</span>
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectForm" v-model="selectForm" class="select-form">
          <div>
            <el-form-item label="组织名称">
              <el-input v-model.trim="selectForm.orgName" placeholder="请输入组织名称"
                        maxlength="64" @keyup.enter.native="submitForm()"/>
            </el-form-item>
            <el-form-item label="组织税号">
              <el-input v-model.trim="selectForm.orgTaxNo" placeholder="请输入组织税号"
                        maxlength="32" @keyup.enter.native="submitForm()"/>
            </el-form-item>
            <el-form-item label="申报类型">
              <el-select v-model="selectForm.declareMode" placeholder="请选择申报类型">
                <el-option v-for="item in declareModeList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @select="handleSelect" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <el-table
        :data="tableData"
        style="width:100%"
        row-key="id"
        border
        stripe
        :header-cell-style="handleHeaderCellStyle"
        :default-expand-all="false"
        max-height="440"
        :expand-row-keys="[SecondaryNode]"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="orgName" label="组织名称" align="left" width="330"></el-table-column>
        <el-table-column prop="orgTaxNo" label="组织税号" width="270"></el-table-column>
        <el-table-column prop="sykjzd" label="适用会计准则" align="left" :formatter="formatSykjzd"/>
        <el-table-column prop="declareMode" label="申报类型" align="left">
          <template slot-scope="scope">
            <div>
              <span>{{ formatDeclareMode(scope.row) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" style="width: 60px" @click="handleDetails(scope.row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getDeclareSettingTree
} from '@/service/declare/setting.js'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'

export default {
  name: 'DecSbzt',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  data () {
    return {
      selectForm: {
        orgName: undefined,
        orgTaxNo: undefined,
        declareMode: undefined
      },
      loading: false,
      tableData: [],
      tableLoading: false,
      SecondaryNode: '',
      sykjzdList: [],
      declareModeList: []
    }
  },
  created () {
    this.submitForm()
    this.getDicts('sykjzd').then(res => {
      this.sykjzdList = res.data
    })
    this.getDicts('dec_declare_mode').then(res => {
      if (res.success) {
        this.declareModeList = [{ value: undefined, label: '全部' }, ...res.data]
      }
    })
  },
  methods: {
    handleSelect () {
      this.submitForm()
    },
    submitForm () {
      this.tableLoading = true
      getDeclareSettingTree(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data
          //默认展开一级组织
          // this.SecondaryNode = '' + this.tableData[0].id
        }
      })
    },
    //重置
    handleReset () {
      this.selectForm = {
        orgName: undefined,
        orgTaxNo: undefined,
        declareMode: undefined
      }
    },
    // //组织名称长度不得超过14
    // handleOrganizationName (row) {
    //   if (row && row.orgName.length > 14) {
    //     return row.orgName.substring(0, 14) + '...'
    //   } else {
    //     return row.orgName
    //   }
    // },
    formatDeclareMode (row) {
      return this.getDictLabel(this.declareModeList, row.declareMode)
    },
    //详情
    handleDetails (row) {
      this.$router.replace({ path: '/declare/setting/detail', query: { id: row.id, isEdit: 'true' } })
    },
    formatSykjzd (row) {
      return this.getDictLabel(this.sykjzdList, row.sykjzd)
    },
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/select.scss";

  .select-header {
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;

    .select-items {
      display: inline-flex;

      div {
        margin-right: 10px;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }

      input {
        border: none;
      }
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }

  .content-main {
    margin: 24px 0 0 0;

    .content-table {
      ::v-deep .el-button {
        padding: 0;
      }
    }
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: left;
    padding: 8px 0;
  }
</style>
